import { useState } from "react";

export default function MagnifyingGlass({
	src,
	width,
	height,
	magnifierHeight = 200,
	magnifieWidth = 200,
	zoomLevel = 2,
}) {
	const [[x, y], setXY] = useState([0, 0]);
	const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
	const [showMagnifier, setShowMagnifier] = useState(false);

	return (
		<div className="relative" style={{ height, width }}>
			<img
				src={src}
				className="h-96 w-full object-contain"
				onMouseEnter={(e) => {
					const elem = e.currentTarget;
					const { width, height } = elem.getBoundingClientRect();
					setSize([width, height]);
					setShowMagnifier(true);
				}}
				onMouseMove={(e) => {
					const elem = e.currentTarget;
					const { top, left } = elem.getBoundingClientRect();
					const x = e.clientX - left;
					const y = e.clientY - top;
					setXY([x, y]);
				}}
				onMouseLeave={() => {
					setShowMagnifier(false);
				}}
				alt="img"
			/>

			{showMagnifier && (
				<div
					className="absolute pointer-events-none border border-lightgray bg-white"
					style={{
						height: `${magnifierHeight}px`,
						width: `${magnifieWidth}px`,
						top: `${y - magnifierHeight / 2}px`,
						left: `${x - magnifieWidth / 2}px`,
						opacity: "1",
						backgroundImage: `url('${src}')`,
						backgroundRepeat: "no-repeat",
						backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel
							}px`,
						backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
						backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
					}}
				></div>
			)}
		</div>
	);
}
