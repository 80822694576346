import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUpload,
	faDownload,
	faTimes,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useSupportingDocumentDelete from "../../actions/hooks/useSupportingDocumentsDelete";
import useSupportingDocumentCreate from "../../actions/hooks/useSupportingDocumentCreate";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import ReceiptUploadForm from "../expenses/ReceiptUploadForm";
import { setRerender } from "../../actions/rerenderAction";
import { setExpense } from "../../actions/expenseAction";
import Alert from "../elements/Alert";
import useShowAlert from "../../actions/hooks/utility/useShowAlert";

export default function ReceiptWrapper({ children }) {
	// Use dispatch
	const dispatch = useDispatch();

	// Fetch expense from store
	const exp = useSelector((store) => store.expense);

	// Fetch user
	const { user } = useContext(UserContext);

	// Initialize states
	const [receipt, setReceipt] = useState(null);
	const [showCancel, setShowCancel] = useState(false);
	const [hasReceipt, setHasReceipt] = useState(
		exp && exp.supporting_documents[0]
	);

	// Use create / delete supporting document hooks
	const { supportingDocumentCreate, supportingDocumentCreateResponse } =
		useSupportingDocumentCreate();
	const { supportingDocumentDelete, supportingDocumentDeleteResponse } =
		useSupportingDocumentDelete();

	const {
		showSuccessAlert,
		setShowSuccessAlert,
		showErrorAlert,
		setShowErrorAlert,
	} = useShowAlert();

	// Rerender component when a supporting document is added
	useEffect(() => {
		if (supportingDocumentDeleteResponse)
			dispatch(setRerender());
	}, [supportingDocumentDeleteResponse]);

	// Create supporting document
	useEffect(() => {
		if (receipt) {
			let formData = new FormData();
			formData.append("file", receipt);
			formData.append("name", receipt.name);
			formData.append("expense_id", exp.id);
			supportingDocumentCreate(formData);
			setHasReceipt(true);
		}
	}, [receipt]);

	useEffect(() => {
		if (
			supportingDocumentCreateResponse &&
			supportingDocumentCreateResponse.status === 200
		) {
			setShowSuccessAlert(true);
			dispatch(setRerender());
			dispatch(
				setExpense({
					...exp,
					supporting_documents: [
						supportingDocumentCreateResponse.data.supportingDocument,
					],
				})
			);
		}
	}, [supportingDocumentCreateResponse]);

	const handleDownload = () => {
		if (exp && exp.supporting_documents[0]) {
			const { file_path, file_name } = exp.supporting_documents[0];
			const fileUrl = `${process.env.REACT_APP_API_URL}/${file_path}`;

			const link = document.createElement('a');
			link.href = fileUrl;
			link.target = '_blank';
			link.click();
		}
	};

	const handleDetach = () => {
		if (exp.supporting_documents[0]) {
			supportingDocumentDelete(exp.supporting_documents[0].id);
			setHasReceipt(false);
			setReceipt(null);
			setShowCancel(false);
			dispatch(setExpense({ ...exp, supporting_documents: [] }));
			setShowSuccessAlert(true);
		}
	};

	const handleShowAttachReceipt = () => {
		setShowCancel(true);
	};

	const handleHideAttachReceipt = () => {
		setShowCancel(false);
	};

	// Footer button that will handle
	const ReceiptActionButton = ({ children, onClick }) => {
		return (
			<div
				className="text-sm flex flex-row gap-2 items-center rounded-full bg-gray-100 border-gray-200 border px-2 py-1 font-semibold hover:cursor-pointer"
				onClick={onClick}
			>
				{children}
			</div>
		);
	};
	return (
		<>
			<div className="max-w-64 border rounded-lg shadow-md">
				<div className="flex flex-col">
					<div className="border-b rounded-lg h-auto sm:h-full">
						{showCancel ? (
							<ReceiptUploadForm onChange={setReceipt} />
						) : (
							children
						)}
					</div>

					<div className="flex flex-row items-center justify-between p-3">

						{/* Show upload option only when no image is available */}
						{!showCancel && !hasReceipt && (
							<ReceiptActionButton onClick={handleShowAttachReceipt}>
								<FontAwesomeIcon icon={faUpload} />
								<span>Upload</span>
							</ReceiptActionButton>
						)}

						{showCancel && !hasReceipt && (
							<ReceiptActionButton onClick={handleHideAttachReceipt}>
								<FontAwesomeIcon icon={faTimes} />
								<span>Cancel</span>
							</ReceiptActionButton>
						)}

						{/* Show download option only when image is available */}
						{hasReceipt && (
							<>
								<ReceiptActionButton onClick={handleDownload}>
									<FontAwesomeIcon icon={faDownload} />
									<span>Download</span>
								</ReceiptActionButton>
								{((exp.state === "open" || exp.state === "unreported") && (exp.user_id && user.id === exp.user_id)) && (
									<ReceiptActionButton onClick={handleDetach}>
										<FontAwesomeIcon icon={faTrash} />
										<span>Detach</span>
									</ReceiptActionButton>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			{showErrorAlert && (
				<Alert message="Failed to update receipt." type="error" duration={2000} />
			)}
			{showSuccessAlert && (
				<Alert
					message="Receipt updated successfully."
					type="success"
					duration={2000}
				/>
			)}
		</>
	);
}
